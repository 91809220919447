// Generated by Framer (20caf11)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./lM2jmjda4-0.js";

const cycleOrder = ["AHUAbb91u", "AyqlLL7O4"];

const serializationHash = "framer-u82Fu"

const variantClassNames = {AHUAbb91u: "framer-v-1nw1tom", AyqlLL7O4: "framer-v-11g31tq"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {XCjGPTapi: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {bounce: 0.2, delay: 0, duration: 4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Menu Close": "AHUAbb91u", "Menu Open": "AyqlLL7O4"}

const getProps = ({height, id, tap, width, ...props}) => { return {...props, cysd_f03b: tap ?? props.cysd_f03b, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "AHUAbb91u"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;tap?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, cysd_f03b, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "AHUAbb91u", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTapaahp08 = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (cysd_f03b) {const res = await cysd_f03b(...args);
if (res === false) return false;}
})

const ref1 = React.useRef<HTMLElement>(null)

const isDisplayed = () => {
if (baseVariant === "AyqlLL7O4") return false
return true
}

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1nw1tom", className, classNames)} data-framer-name={"Menu Close"} data-highlight layoutDependency={layoutDependency} layoutId={"AHUAbb91u"} onTap={onTapaahp08} ref={ref ?? ref1} style={{backgroundColor: "rgba(255, 255, 255, 0.9)", ...style}} {...addPropertyOverrides({AyqlLL7O4: {"data-framer-name": "Menu Open"}}, baseVariant, gestureVariant)}><motion.div className={"framer-155c497"} data-framer-name={"Line"} layoutDependency={layoutDependency} layoutId={"DYUDcKhWzPvzS4hW9x"} style={{backgroundColor: "var(--token-b740f1d8-819f-424e-8ed4-13557f40bfa1, rgb(20, 34, 81))", borderBottomLeftRadius: 2, borderBottomRightRadius: 2, borderTopLeftRadius: 2, borderTopRightRadius: 2, rotate: 0}} variants={{AyqlLL7O4: {rotate: 225}}}/>{isDisplayed() && (<motion.div className={"framer-1snmpmj"} data-framer-name={"Line"} layoutDependency={layoutDependency} layoutId={"NwUXZOpKU"} style={{backgroundColor: "var(--token-b740f1d8-819f-424e-8ed4-13557f40bfa1, rgb(20, 34, 81))", borderBottomLeftRadius: 2, borderBottomRightRadius: 2, borderTopLeftRadius: 2, borderTopRightRadius: 2}}/>)}<motion.div className={"framer-1maj0rd"} data-framer-name={"Line"} layoutDependency={layoutDependency} layoutId={"nWarkUTi0"} style={{backgroundColor: "var(--token-b740f1d8-819f-424e-8ed4-13557f40bfa1, rgb(20, 34, 81))", borderBottomLeftRadius: 2, borderBottomRightRadius: 2, borderTopLeftRadius: 2, borderTopRightRadius: 2, rotate: 0}} variants={{AyqlLL7O4: {rotate: -45}}}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-u82Fu.framer-1xppypu, .framer-u82Fu .framer-1xppypu { display: block; }", ".framer-u82Fu.framer-1nw1tom { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 3px; height: 15px; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: 20px; }", ".framer-u82Fu .framer-155c497, .framer-u82Fu .framer-1snmpmj, .framer-u82Fu .framer-1maj0rd { flex: none; height: 3px; position: relative; width: 100%; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-u82Fu.framer-1nw1tom { gap: 0px; } .framer-u82Fu.framer-1nw1tom > * { margin: 0px; margin-bottom: calc(3px / 2); margin-top: calc(3px / 2); } .framer-u82Fu.framer-1nw1tom > :first-child { margin-top: 0px; } .framer-u82Fu.framer-1nw1tom > :last-child { margin-bottom: 0px; } }", ".framer-u82Fu.framer-v-11g31tq.framer-1nw1tom { height: 17px; }", ".framer-u82Fu.framer-v-11g31tq .framer-155c497 { left: calc(50.00000000000002% - 20px / 2); order: 0; position: absolute; top: calc(47.05882352941179% - 3px / 2); width: 20px; z-index: 1; }", ".framer-u82Fu.framer-v-11g31tq .framer-1maj0rd { left: 0px; order: 1; position: absolute; right: 0px; top: calc(46.66666666666669% - 3px / 2); width: unset; z-index: 1; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 15
 * @framerIntrinsicWidth 20
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"AyqlLL7O4":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"cysd_f03b":"tap"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerlM2jmjda4: React.ComponentType<Props> = withCSS(Component, css, "framer-u82Fu") as typeof Component;
export default FramerlM2jmjda4;

FramerlM2jmjda4.displayName = "Icon Menu";

FramerlM2jmjda4.defaultProps = {height: 15, width: 20};

addPropertyControls(FramerlM2jmjda4, {variant: {options: ["AHUAbb91u", "AyqlLL7O4"], optionTitles: ["Menu Close", "Menu Open"], title: "Variant", type: ControlType.Enum}, cysd_f03b: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(FramerlM2jmjda4, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})